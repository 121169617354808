import actionsHelper from 'utils/actionsHelper';
const names: any = [
  'GET_HOMEPAGENEWS',
  'GET_NEWS',
  'GET_LASTESTNEWS',
  'GET_TAGS',
  'GET_NEWSDETAILS'
];

const createActions: any = actionsHelper(names);

export default {
  ...createActions.types,
  ...createActions.actions
}
