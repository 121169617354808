import axios, { AxiosRequestConfig, AxiosResponse } from 'axios';

const errorHandler = (error: { response: any }): void => {
  const { response } = error;
  throw (response);
};

class Request {
  name: string;
  constructor(name: string) {
    this.name = name;
  }

  get request() {
    const name: any = this.name;
    let request: any = axios.create({
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      }
    });

    request.interceptors.request.use(function (config: AxiosRequestConfig): any {
      let json: any = localStorage.getItem('account-auth');
      const accountAuth: any = JSON.parse(json) || {};
      config.url = window.Config.NODE_ENV === 'local' ? `/${name}-api${config.url}` : `${window.Config[name == 'global' ? 'API_HOST' : `${String(name).toUpperCase().replace(/-/g, '_')}_API_HOST`]}${config.url}`;
      // config.headers['Authorization'] = `Bearer ${accountAuth.token}`
      return config
    });

    request.interceptors.response.use(function (response: AxiosResponse<any>): any {
      return response.data
    }, errorHandler)

    return request;
  }
}

export default Request;