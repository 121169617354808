export default [
  {
    path: '/',
    component: 'layouts/default',
    redirect: {
      enterprise: '/account/settings',
      investor: '/account/settings',
    },
    routes: [
      {
        title: 'title.404.notfound',
        Icon: 'components/Icons/IconBlockchain',
        path: '/404',
        component: '404',
        order: 999,
        routes: [],
        navList: false,
        breadcrumb: false,
      },
      {
        title: 'leadagilex-official-website.title',
        Icon: 'AccountCircleOutlinedIcon',
        path: '/leadagilex-official-website',
        authorize: ['default', 'investor', 'enterprise'],
        breadcrumbLink: false,
        order: 1,
        isProject: true,
        routes: [
          {
            title: '首頁',
            path: '/leadagilex-official-website/',
            component: 'leadagilex-official-website/Index',
            navList: false,
            authorize: ['default', 'investor', 'enterprise'],
            routes: [
              {
                title: '隱私權政策',
                path: '/leadagilex-official-website/privacy',
                component: 'leadagilex-official-website/Privacy',
              },
              {
                title: '夥伴頁',
                path: '/leadagilex-official-website/partners/:id',
                component: 'leadagilex-official-website/Partners',
              },
              {
                title: '最新消息',
                path: '/leadagilex-official-website/news/',
                component: 'leadagilex-official-website/news/Index',
                routes: [
                  {
                    title: '消息',
                    path: '/leadagilex-official-website/news/:id',
                    component: 'leadagilex-official-website/news/Details',
                  },
                ],
              },
              {
                title: '關於LeadAgileX',
                path: '/leadagilex-official-website/about',
                component: 'leadagilex-official-website/About',
              },
            ],
          },
        ],
      },
    ],
    isProject: true,
  },
];
