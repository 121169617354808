export default {
  locale: 'en_US',
  languageSettings: {
    isInitial:false
  },
  globalDialog: {
    confirmBol: false,
    visible: false,
    close: false,
    title: '',
    content: '',
    component: null,
    confirmText: 'action.confirm',
    loading: false,
    cancelText: 'action.cancel',
    confirmAction: '',
    cancelAction: '',
    iconTitle: '',
    icon: '',
    contentHasPadding: true,
  },
  snackbar: {
    visible: false,
    variant: 'success',
    autoHideDuration: 3000,
    message: '',
    anchorOrigin: {},
    actionComponent: null,
  },
  breadcrumbName: {},
  uploadFile: {
    loading: false,
    loaded: false,
    data: {}
  },
  sharedData: {
    user: {
      loading: false,
      loaded: false,
      data: {
        current: 'default'
      }
    },
    socket: <any> {}
  },
  captcha: {
    loading: false,
    loaded: false,
    data: {}
  },
  moduleAcl:{},
  meta: {
    loading: false,
    loaded: false,
    data: {},
  },
  featureAcl: {},
  deleteUserAccessToken: {
    loading: false,
    loaded: false,
  },
  startUp: {
    loading:false,
    loaded: false
  }
};